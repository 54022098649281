import store from "@/store";
import { handleToken } from "@/utils/Foundation";
import axios from "axios";
import { Toast } from "vant";
import Vue from "vue";
import i18n from "@/i18n";

const qs = require("qs");

Vue.use(Toast);

const service = axios.create({
  // baseURL: process.env.VUE_APP_API,
  timeout: 15000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

service.interceptors.request.use(
  (config) => {
    let { loading } = config.headers;
    const accessToken = store.getters.token;
    const language = store.getters.language;
    const uuid = store.getters.uuid;
    if (accessToken && config.headers.needToken) {
      config.headers["Authorization"] = accessToken;
    }
    config.headers["language"] = language;
    config.headers["uuid"] = uuid;
    // 是否需要loading
    if (loading !== false) {
      config.loading = Toast.loading({
        overlay: false,
        message: i18n.t("loadingText") + "...",
        duration: "0",
        forbidClick: true,
      });
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
    await closeLoading(response);
    const { code, msg } = response.data;
    if (code === 401) {
      store.dispatch("user/deleteToken");
      store.dispatch("app/deleteUuid");
      Toast("请先登录");
    } else if (code !== 200) {
      Toast(msg);
    }
    return response.data;
  },
  async (error) => {
    await closeLoading(error);
    const error_response = error.response || {};
    if (error_response.status === 401) {
      store.dispatch("user/deleteToken");
      store.dispatch("app/deleteUuid");
      Toast("请先登录");
      return Promise.reject(error);
    } else {
      Toast("服务器错误，请稍后再试");
    }
    return Promise.reject(error);
  }
);

export default function request(options) {
  return new Promise((resolve, reject) => {
    if (options.headers.needToken && !store.getters.token) {
      handleToken().then(async (token) => {
        await store.dispatch("user/setToken", token);
        service(options).then(resolve).catch(reject);
      });
    } else {
      service(options).then(resolve).catch(reject);
    }
  });
}

/**
 * 接口api，base：公共，api：用户端
 */
export const baseUrl = {
  base: process.env.VUE_APP_BASE_API,
  api: process.env.VUE_APP_API,
};

/**
 * 关闭全局加载
 * @param target
 */
const closeLoading = (target) => {
  if (!target.config || !target.config.loading) return true;
  return new Promise((resolve) => {
    setTimeout(() => {
      Toast.clear();
      resolve();
    }, 200);
  });
};
